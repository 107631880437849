<template>
  <div>
    redirecting...
  </div>
</template>
<script>
import {axiosPatchLog} from "@/utils/axiosWrapper";

export default {
  mounted() {
    const link = this.$router.currentRoute.params.link
    axiosPatchLog('/referral/' + link)
        .then(() => console.log(`Code ${link} saved.`))
        .catch(e => console.warn(e))
        .finally(() => link === "ggz" ? this.$router.push('/nl') : this.$router.push({name: 'Landing'}))
  }
}
</script>